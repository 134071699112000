//jQuery - Ready Function v1
//$(document).ready(function(){
    /* your code */ 
//});
//jQuery - Ready Function v2 - Prevents Errors
// jQuery(document).ready(function($) {
//  /* your code */
// });

jQuery(document).ready(function($) {
    console.log("jq ready.");

    //Open - Close Menu panel
    let divSidebar = $("#sidebar");
    let btnMenuOpen = $("#cta-menu-open");
    let btnMenuClose = $("#cta-menu-close");
    btnMenuOpen.on("click", function(){
        divSidebar.animate({
            'left' : '0'
        });
        btnMenuOpen.hide();
    });
    btnMenuClose.on("click", function(){
        divSidebar.animate({
            'left' : '-240px'
        });
        btnMenuOpen.show();
    });
    
});

